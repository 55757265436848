import FundUnicefKrTemplate from '@/page-blocks/about-us/clear/fund/unicef-kr/FundUnicefKrTemplate';
import ReportKorea2018 from '@/page-blocks/about-us/clear/fund/unicef-kr/report.korea_2018';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicefKr2018: React.FC<PageProps> = (props) => (
  <FundUnicefKrTemplate year={2018} {...props}>
    <ReportKorea2018 />
  </FundUnicefKrTemplate>
);

export default FundUnicefKr2018;
